#headerimage {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: calc(50vh - 63px);
  min-height: 250px;
  max-height: 500px;
  padding: 25px 50px 75px;
  box-shadow: $box-shadow-area-primary;
  .image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $color-ci-blue url('../../images/background/macbook.jpg') no-repeat center;
    background-size: cover;
    &:before {
      opacity: 0.35;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $color-ci-blue;
      content: "";
    }
  }
  .headerimage-container {
    position: relative;
    width: 100%;
    .headline {
      @include font-headline-primary();
      color: #fff;
      text-align: center;
    }
  }
}
