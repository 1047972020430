#page {
  min-height: 100vh;
  padding-top: 63px;
  #content {
    min-height: calc(100vh - 63px);
    #main {
      position: relative;
      width: 100%;
      max-width: 1100px;
      margin: 0 auto;
      padding: 50px;
      > h1 {
        @include font-headline-primary();
        text-align: center;
        &:not(:last-child) {
          margin-bottom: 50px;
        }
      }
      .watch-video {
        position: absolute;
        top: 15px;
        right: 50px;
        @include font-bold(10px);
        color: $color-ci-purple;
        text-transform: uppercase;
        margin-top: 10px;
        .icon {
          vertical-align: middle;
          margin-top: -2px;
          margin-right: 5px;
          display: inline-flex;
        }
      }
      .table {
        position: relative;
        display: table;
        width: 100%;
        &.wbts-table .tr .td {
          width: 20%;
          &:first-child {
            width: 40%;
          }
        }
        &:before {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          height: 1px;
          background: #142847;
          content: "";
          opacity: 0.2;
        }
        .thead {
          display: table-header-group;
          .tr .td {
            @include font-bold(12px);
            text-transform: uppercase;
          }
        }
        .tbody {
          display: table-row-group;
          .tr:not(:first-child):after {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: 1px;
            background: #142847;
            content: "";
            opacity: 0.1;
          }
        }
        .tr {
          display: table-row;
          position: relative;
          .td {
            display: table-cell;
            padding: 12px;
            vertical-align: middle;
            a {
              color: $color-ci-pink;
              transition: color $duration-hover;
              &:hover {
                color: $color-ci-pink-hover;
              }
            }
          }
        }
      }
    }
  }
}
