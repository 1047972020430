input[type="text"], input[type="email"], input[type="password"], , input[type="search"], textarea, .input-wysiwyg {
  @include font-regular(12px);
  width: 100%;
  min-height: 22px;
  margin: 0;
  padding: 3px 0 2px;
  background: none;
  border: none;
  border-bottom: 1px solid $color-ci-blue;
  color: $color-font-primary;
  line-height: 16px;
  appearance: none;
  &.light {
    border-bottom-color: #fff;
    color: #fff;
  }
  &:invalid {
    box-shadow: none;
  }
  &::-webkit-input-placeholder {
    @include font-regular(12px);
    opacity: 1;
    color: $color-ci-pink;
    line-height: 16px;
  }
  &::-moz-placeholder {
    @include font-regular(12px);
    opacity: 1;
    color: $color-ci-pink;
    line-height: 16px;
  }
  &:-ms-input-placeholder {
    @include font-regular(12px);
    opacity: 1;
    color: $color-ci-pink;
    line-height: 16px;
  }
  &:-moz-placeholder {
    @include font-regular(12px);
    opacity: 1;
    color: $color-ci-pink;
    line-height: 16px;
  }
}
textarea {
  resize: none;
}
select {
  @include font-regular(10px);
  padding: 6px 5px 4px;
  background: none;
  border: 1px solid $color-ci-blue;
  border-radius: 0;
  color: $color-font-primary;
  line-height: 15px;
  appearance: none;
  &.light {
    border-color: #fff;
    color: #fff;
  }
}
.input.input-select.simple {
  height: 26px;
  margin-top: 7px;
  margin-bottom: 7px;
  > [class$="-container"] > div {
    &:nth-child(2), &:first-child:last-child {
      position: relative;
      height: 26px;
      &:before {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 1px;
        background: $color-ci-blue;
        content: ""
      }
      > div:first-child {
        padding-left: 0;
      }
      > div:nth-child(2) {
        [class$="-indicatorSeparator"] {
          display: none;
        }
        [class$="-indicatorContainer"] {
          padding: 0;
        }
      }
    }
  }
}
.ui.dropdown {
  background: none !important;
  width: 100%;
  border: 0 !important;
  border-bottom: 1px solid $color-font-primary !important;
  border-radius: 0 !important;
  min-height: 22px !important;
  margin: 0 !important;
  padding: 3px 0 2px !important;
  > .text {
    @include font-regular(12px);
    margin: 0 !important;
    color: $color-ci-pink !important;
  }
  .dropdown.icon {
    padding: 5px 0 !important;
    &:before {
      font-size: 12px;
      color: $color-font-primary;
    }
  }
  .ui.label {
    @include font-regular(12px);
    background: $color-ci-purple-gradient-diagonal !important;
    color: #fff !important;
    padding: 2px 8px;
  }
}
.field.light .ui.dropdown {
  border-color: #fff !important;
  .dropdown.icon:before {
    color: #fff;
  }
}
