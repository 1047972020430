.list {
  &.attachments {
    &.edit-attachment {
      .attachments {
        width: calc(100vw - 475px + 16px - 25px - ((100vw - 100%) / 2));
      }
      .attachment-info-box {
        transform: none;
      }
    }
    .filters {
      justify-content: space-between;
    }
  }
  &.list-wbts {
    &.list-wbt-templates {
      .main-filters {
        margin: 0 0 -5px;
      }
      .teasers .teaser .main {
        background: $color-ci-blue;
        color: #fff;
      }
    }
    .wbts-table {
      margin-top: -40px;
      &:before {display: none;}
      .more-options-toggle {
        position: absolute;
        z-index: 50;
        top: 50%;
        right: 0;
        padding: 0;
        width: 40px;
        height: 25px;
        min-width: 0;
        margin-top: -13px;
        cursor: pointer;
        &:not(.active) {
          background: transparent;
          &:before, &:after {
            display: none;
          }
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            &.close {
              display: none;
            }
            svg {
              circle, path {
                fill: $color-ci-purple;
              }
            }
          }
        }
        &.active {
          z-index: 100;
          width: 25px;
          .icon {
            &.open {
              display: none;
            }
            &.close {
              display: flex;
            }
          }
        }
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          &.close {
            display: none;
          }
          svg {
            circle, path {
              fill: #fff;
            }
          }
        }
      }
      .more-options {
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 55;
        margin-top: -13px;
        top: 50%;
        right: 0;
        padding-top: 25px;
        background: #fff;
        .option {
          display: flex;
          padding: 5px 10px;
          border: 1px solid $color-border-grey;
          &:hover {
            .icon svg path {
              fill: $color-ci-pink;
            }
            .label {
              color: $color-ci-pink;
            }
          }
          &:not(:first-child) {
            margin-top: -1px;
          }
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            vertical-align: middle;
            svg path {
              fill: $color-ci-purple;
              transition: fill $duration-hover;
            }
          }
          .label {
            @include font-bold(10px);
            padding-top: 2px;
            vertical-align: middle;
            color: $color-ci-purple;
            transition: color $duration-hover;
          }
        }
      }
    }
  }
  .wbts-table {
    margin-top: -30px;
    &:before {
      display: none !important;
    }
    button {
      color: $color-ci-pink;
      transition: color $duration-hover;
      &:hover {
        color: $color-ci-pink-hover;
      }
    }
  }
  .main-button {
    margin: -25px 0 25px;
    text-align: center;
  }
  .filters {
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    padding: 10px 0;
    border-bottom: 1px solid $color-ci-blue;
    .filters-tabs {
      margin: 5px 0 -10px;
      .button {
        min-width: 145px;
        &:not(:last-child) {
          margin-right: 10px;
        }
        &.active {
          cursor: default;
          background: $color-ci-blue;
          &:before {
            border: transparent !important;
          }
          .label {
            color: #fff;
          }
          &:after {
            opacity: 0;
          }
        }
      }
    }
    .button {
      min-width: 0;
      padding: 12px 20px 10px;
    }
    .categories-filters {
      flex: 1;
      width: 25%;
      float: left;
      font-size: 0;
      padding: 5px 0;
      .filter-button {
        opacity: 0.4;
        padding: 5px 0;
        transition: opacity $duration-hover;
        &:not(:last-child) {
          margin-right: 20px;
        }
        &.active {
          opacity: 1;
        }
        .label {
          @include font-bold(12px);
          text-transform: uppercase;
        }
        .count {
          @include font-regular(12px);
          display: inline-block;
          margin-left: 10px;
          padding: 3px 10px 2px;
          background: $color-ci-blue;
          border-radius: 19px;
          line-height: 15px;
          color: #fff;
        }
      }
    }
    .main-filters {
      > * {
        float: left;
        width: auto;
        min-width: 160px;
        margin-left: 45px !important;
      }
      .input.search {
        position: relative;
        margin: 7px 0;
        input[type="search"] {
          padding: 3px 26px 2px 0;
          font-weight: 700;
          line-height: 20px;
          &::-webkit-input-placeholder {
            line-height: 20px;
            font-weight: 700;
            text-transform: uppercase;
          }
          &::-moz-placeholder {
            line-height: 20px;
            font-weight: 700;
            text-transform: uppercase;
          }
          &:-ms-input-placeholder {
            line-height: 20px;
            font-weight: 700;
            text-transform: uppercase;
          }
          &:-moz-placeholder {
            line-height: 20px;
            font-weight: 700;
            text-transform: uppercase;
          }
        }
        button {
          position: absolute;
          top: 0;
          right: 0;
          width: 25px;
          height: 25px;
          .icon svg path {
            fill: $color-ci-pink;
            stroke: $color-ci-pink;
          }
        }
      }
      .view {
        display: flex;
        min-width: auto;
        align-items: center;
        height: 26px;
        margin: 7px 0;
        .label {
          @include font-bold(12px);
          display: inline-block;
          line-height: 26px;
          text-transform: uppercase;
        }
        .view-buttons {
          margin-left: 12px;
          font-size: 0;
          > * {
            vertical-align: middle;
            &:not(:last-child) {
              margin-right: 10px;
            }
          }
          button {
            overflow: hidden;
            opacity: 0.4;
            transition: opacity $duration-hover;
            &.active {
              opacity: 1;
            }
            svg {
              float: left;
            }
          }
        }
      }
    }
  }
  .teasers {
    position: relative;
    z-index: 5;
    display: flex;
    flex-flow: wrap;
    position: relative;
    margin: -8px;
    .teaser {
      display: flex;
      flex-direction: column;
      width: calc(33.333% - 16px);
      margin: 8px;
      padding: 0;
      text-align: center;
      color: $color-ci-blue;
      &:nth-child(2) .image {
        background-image: url('http://localhost:1337/uploads/medium_ELE_Yoga_1_1b93902fe6.jpeg');
      }
      &:before, &:after {
        background: none !important;
        border-color: transparent !important;
      }
      &.teaser-wbt-template {
        position: relative;
        &.module-template-teaser {
          .image {
            height: 0;
            padding: 0;
            overflow: visible;
          }
          .button {
            &.button-edit {
              float: left;
              width: 100%;
            }
            &.button-use {
              display: none;
            }
          }
        }
        .button {
          min-width: 0;
          cursor: pointer;
          &.button-edit {
            float: left;
            width: 50%;
          }
          &.button-use {
            position: absolute;
            bottom: 0;
            right: 0;
            float: right;
            width: 50%;
          }
        }
      }
      .image {
        position: relative;
        padding-bottom: 56.25%;
        overflow: hidden;
        .img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: $color-ci-blue url('http://localhost:1337/uploads/medium_ELE_PB_Langhantel_5_b9de7fe0dd.jpeg') no-repeat center;
          background-size: cover;
        }
        .more-options-toggle {
          position: absolute;
          z-index: 100;
          top: 0;
          right: 0;
          padding: 0;
          width: 40px;
          height: 25px;
          min-width: 0;
          cursor: pointer;
          &.active {
            width: 25px;
            .icon {
              &.open {
                display: none;
              }
              &.close {
                display: flex;
              }
            }
          }
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            &.close {
              display: none;
            }
            svg {
              circle, path {
                fill: #fff;
              }
            }
          }
        }
        .more-options {
          display: flex;
          flex-direction: column;
          position: absolute;
          z-index: 50;
          top: 0;
          right: 0;
          padding-top: 25px;
          background: #fff;
          .option {
            display: flex;
            padding: 5px 10px;
            border: 1px solid $color-border-grey;
            &:hover {
              .icon svg path {
                fill: $color-ci-pink;
              }
              .label {
                color: $color-ci-pink;
              }
            }
            &:not(:first-child) {
              margin-top: -1px;
            }
            .icon {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 10px;
              vertical-align: middle;
              svg path {
                fill: $color-ci-purple;
                transition: fill $duration-hover;
              }
            }
            .label {
              @include font-bold(10px);
              padding-top: 2px;
              vertical-align: middle;
              color: $color-ci-purple;
              transition: color $duration-hover;
            }
          }
        }
      }
      .main {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 15px 9px;
        background: #fff;
        border: 1px solid $color-border-grey;
        &:not(:first-child) {
          padding-top: 14px;
          border-top: none;
        }
        &:not(:last-child) {
          padding-bottom: 14px;
          border-bottom: none;
        }
        .main-top {
          display: flex;
          margin-bottom: 18px;
          > div {
            @include font-bold(12px);
            flex: 1;
            padding: 0 5px;
            text-align: center;
            text-transform: uppercase;
            &:first-child:not(:last-child) {
              text-align: left;
            }
            &:last-child:not(:first-child) {
              text-align: right;
            }
          }
          .date, .modules {
            padding-top: 2px;
          }
        }
        .headline {
          @include font-headline-secondary();
          flex: 1;
          margin-bottom: 15px;
        }
        .authors {
          display: flex;
          flex-flow: wrap;
          justify-content: center;
          padding: 10px 0;
          .author {
            width: 36px;
            height: 36px;
            margin: 5px;
            background: $color-ci-blue url() no-repeat center;
            background-size: cover;
            border-radius: 50%;
          }
        }
      }
      .button:not(.more-options-toggle) {
        border-radius: 0;
        &:after {
          box-shadow: none;
        }
      }
    }
  }
  .attachments {
    display: flex;
    flex-flow: wrap;
    position: relative;
    width: calc(100% + 16px);
    max-width: calc(100% + 16px);
    margin: -8px;
    transition: width $duration-slide;
    .attachment {
      display: flex;
      flex-direction: column;
      position: relative;
      width: calc(16.6666% - 16px);
      margin: 8px;
      padding-bottom: calc(16.6666% - 16px);
      background: $color-ci-blue none no-repeat center;
      background-size: cover;
      cursor: pointer;
      overflow: hidden;
      &:hover:before {
        opacity: 1;
      }
      &:before {
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: $box-shadow-button;
        content: "";
        transition: opacity $duration-hover;
      }
      &.svg {
        background-color: #fff;
        background-size: auto;
      }
      .audio {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        width: 100%;
        height: 100%;
        background: #fff;
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          width: 100%;
          height: 80%;
          svg path {
            fill: #9a9a9a;
          }
        }
        .label {
          @include font-bold(10px);
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 33.333%;
          padding: 5px 15px;
          text-align: center;
        }
      }
      .video {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: #fff;
        .label {
          @include font-bold(10px);
          padding: 5px 15px;
          text-align: center;
        }
      }
    }
  }
  .attachment-info-box {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 100;
    top: 188px;
    right: 0;
    bottom: 0;
    width: 475px;
    overflow-y: auto;
    overflow-x: hidden;
    background: $color-ci-blue;
    transform: translateX(100%);
    transition: transform $duration-slide;
    @media (max-height: 768px) {
      top: 0;
    }
    .close {
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      min-width: 0;
      height: 50px;
      padding: 0;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .image {
      position: relative;
      width: 100%;
      padding-bottom: 56.25%;
      .img {
        position: absolute;
        top: 1px;
        right: 1px;
        bottom: 0;
        left: 1px;
        background: $color-background-grey url() no-repeat center;
        background-size: cover;
      }
    }
    .video {
      position: relative;
      width: 100%;
      padding: 1px 1px 0;
      video {
        width: 100%;
      }
    }
    .audio {
      position: relative;
      width: 100%;
      padding: 1px 51px 0 1px;
      audio {
        width: 100%;
      }
    }
    .info {
      flex: 1;
      padding: 30px 35px;
      color: #fff;
      &:nth-child(2) {
        margin-top: 50px;
      }
      .headline {
        @include font-headline-secondary();
        text-align: center;
      }
      .key-info {
        display: flex;
        margin-top: 25px;
        margin-bottom: 10px;
        text-align: center;
        .column {
          flex: 1;
          .label {
            @include font-bold(12px);
            text-transform: uppercase;
          }
        }
      }
      .inputs {
        display: flex;
        flex-flow: wrap;
        margin: 0 -20px;
        .input {
          width: calc(100% - 40px);
          margin: 0 20px;
          &:not(:last-child) {
            margin-bottom: 15px;
          }
          &.w50 {
            width: calc(50% - 40px);
          }
        }
      }
    }
    .bottom-buttons {
      text-align: center;
      padding: 0 10px 30px;
      .button {
        width: calc(50% - 50px);
        min-width: 0;
        margin: 0 25px;
        padding: 12px 25px 10px;
      }
    }
  }
}
