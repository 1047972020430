#profile-settings {
  position: relative;
  padding: 25px 75px;
  background: #fff;
  &.edit-settings .wrapper .profile-form {
    display: block;
    .field {
      &:not(:last-child) {
        margin-bottom: 18px;
      }
      input[type="text"], input[type="password"] {
        border-color: $color-ci-blue;
      }
    }
  }
  .wrapper {
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    text-align: center;
    .profile-image {
      position: relative;
      width: 148px;
      height: 148px;
      margin: 0 auto 25px;
      background: $color-ci-blue;
      border-radius: 50%;
      .button {
        position: absolute;
        right: 0;
        bottom: 0;
        min-width: 50px;
        width: 34px;
        min-width: 0;
        height: 34px;
        padding: 12px 0;
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          position: absolute;
          top: 0;
        }
      }
      input {
        display: none;
      }
    }
    .name {
      @include font-headline-secondary();
      margin-bottom: 25px;
    }
    .profile-form {
      display: inline-block;
      .field {
        text-align: left;
        &:not(:last-child) {
          margin-bottom: 5px;
        }
        .label {
          @include font-bold(12px);
          text-transform: uppercase;
        }
        input[type="text"], input[type="password"] {
          min-width: 0;
          font-weight: 400;
          border-color: transparent;
        }
        .error-message {
          margin-top: 5px;
          color: #ff0000;
          font-size: 12px;
        }
      }
      button {
        margin: 25px 0 10px;
      }
    }
  }
  .edit-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    min-width: 0;
    height: 50px;
    padding: 0;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px;
      top: 0;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}
#profile-options {
  width: 100%;
  max-width: 440px;
  margin: 0 auto;
  padding-bottom: 50px;
  text-align: center;
  &:not(:first-child) {
    margin-top: 80px;
  }
  .section {
    &:not(:last-child) {
      margin-bottom: 60px;
    }
    h2 {
      @include font-headline-secondary();
      margin-bottom: 25px;
    }
    .field {
      position: relative;
      padding: 10px 0px 25px;
      &:after {
        position: absolute;
        height: 1px;
        background: $color-ci-blue;
        opacity: 0.2;
        content: "";
        left: 0;
        right: 0;
        bottom: 0;
      }
      .input-switch {
        position: relative;
        overflow: hidden;
        > .label {
          float: left;
          width: 50%;
          line-height: 20px;
          text-align: left;
        }
        .reset-tooltips {
          @include font-bold(11px);
          position: absolute;
          left: 50%;
          top: 0;
          transform: translate3d(-50%, 0, 0);
          line-height: 20px;
          color: $color-ci-pink;
          text-transform: uppercase;
        }
        input[type="checkbox"] {
          display: none;
          &:checked + label > .switch {
            &:before {
              opacity: 1;
            }
            .rail .label {
              &.label-on {
                opacity: 1;
              }
              &.label-off {
                opacity: 0;
              }
            }
            .handle {
              left: calc(100% - 18px);
            }
          }
        }
        label {
          @include font-regular(14px);
          display: inline-flex;
          position: relative;
          width: 50px;
          float: right;
          cursor: pointer;
          justify-content: flex-end;
          user-select: none;
          > .switch {
            position: relative;
            overflow: hidden;
            border-radius: 10px;
            white-space: nowrap;
            &:before {
              opacity: 0.5;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              background: $color-ci-purple-gradient-diagonal;
              content: "";
              transition: opacity $duration-hover;
            }
            .rail {
              position: relative;
              padding: 0 6px;
              .label {
                @include font-bold(9px);
                display: block;
                width: 100%;
                padding: 0 2px;
                text-align: center;
                line-height: 20px;
                text-transform: uppercase;
                transition: opacity $duration-hover;
                color: #fff;
                &.label-on {
                  opacity: 0;
                  padding-right: 18px;
                }
                &.label-off {
                  margin-top: -20px;
                  padding-left: 18px;
                }
              }
            }
            .handle {
              position: absolute;
              top: 3px;
              left: 4px;
              width: 14px;
              height: 14px;
              background: #fff;
              border: 1px solid $color-border-grey;
              border-radius: 7px;
              transition: left $duration-hover;
            }
          }
        }
      }
    }
    .list {
      width: 100%;
      max-width: 380px;
      margin: 0 auto;
      ul li {
        position: relative;
        padding: 5px 29px;
        &:after {
          position: absolute;
          height: 1px;
          background: $color-ci-blue;
          opacity: 0.2;
          content: "";
          left: 0;
          right: 0;
          bottom: 0;
        }
        .label {
          line-height: 24px;
        }
        button {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 24px;
          &:hover .icon svg path {
            fill: $color-ci-pink-hover;
          }
          .icon svg path {
            fill: $color-ci-pink;
            transition: fill $duration-hover;
          }
        }
      }
      .add-li {
        margin-top: 5px;
        input[type="text"] {
          font-size: 14px;
          text-align: center;
          border-color: transparent;
          line-height: 18px;
          cursor: pointer;
          &.focus {
            border-color: rgba(28, 37, 51, 0.2);
            cursor: auto;
          }
          &::-webkit-input-placeholder {
            font-size: 14px;
            line-height: 18px;
          }
          &::-moz-placeholder {
            font-size: 14px;
            line-height: 18px;
          }
          &:-ms-input-placeholder {
            font-size: 14px;
            line-height: 18px;
          }
          &:-moz-placeholder {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
  }
}
